import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';

import { navigate } from 'gatsby';

import { resetPassword } from '../lib/api';
import { userLoggedIn } from '../redux/actions';

import { Base, Jumbo } from '../components/common';
import SEO from '../components/seo';

import tr from '../../translations'; 

import './login.css';
import InfoModal from '../components/common/modals/InfoModal';
import withLocation from '../hoc/withLocation';

class LoginPage extends React.Component {
  state = {
    loaded: false,
    completed: false,
    password: '',
    passwordRepeat: '',
    error: null,
    message: '',
    show: false,
  };

  componentDidMount() {
    const { code } = this.props.search;

    if (!code) {
      return navigate('/');
    }

    this.setState({ loaded: true });
  }

  handleSubmit = async (e) => {
    const { code } = this.props.search;
    const { password, passwordRepeat } = this.state;

    e.preventDefault();
    e.stopPropagation();

    if (password.length < 8) {
      this.setState({
        message: tr('password.submit-errorLength'),
        show: true,
      });
      return;
    }

    if (password !== passwordRepeat) {
      this.setState({
        message: tr('password.submit-errorRepeat'),
        show: true,
      });
      return;
    }

    let completed = false;
    let message;
    try {
      await resetPassword(password, code);
      message = tr('password.submit-confirmed');
      completed = true;
    } catch (ex) {
      message = ex.message;
    }

    this.setState({
      completed,
      message,
      show: true,
    });

    return false;
  };

  handlePasswordChanged = ({ target: { value } }) => {
    this.setState({ password: value });
  };

  handlePasswordRepeatChanged = ({ target: { value } }) => {
    this.setState({ passwordRepeat: value });
  };

  handleClose = async () => {
    const { completed } = this.state;
    this.setState({ show: false });
    if (completed) {
      await navigate('/login');
    }
  };

  render() {
    const { loaded, password, passwordRepeat, message, show } = this.state;

    if (!loaded) {
      return <div>Loading...</div>;
    }

    return (
      <Base hideFooter>
        <SEO title={tr('password.title')} />
        <Jumbo className="login">
          <InfoModal show={show} onClose={this.handleClose}>
            {message}
          </InfoModal>
          <Row>
            <Col lg={4} />
            <Col
              lg={4}
              style={{
                padding: '1rem',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                border: 'white 1px solid',
              }}
            >
              <h2>{tr('password.title')}</h2>

              <p>{tr('password.text')}</p>

              <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>{tr('password.password-label')}</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={this.handlePasswordChanged}
                  />
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label>{tr('password.repeatPassword-label')}</Form.Label>
                  <Form.Control
                    type="password"
                    value={passwordRepeat}
                    onChange={this.handlePasswordRepeatChanged}
                  />
                </Form.Group>

                <Button variant="primary" type="submit" style={{ marginBottom: '1rem' }} block>
                  {tr('password.submit')}
                </Button>
              </Form>
            </Col>
            <Col lg={4} />
          </Row>
        </Jumbo>
      </Base>
    );
  }
}

export default connect(({ user }) => ({ user }), { actionUserLoggedIn: userLoggedIn })(
  withLocation(LoginPage)
);
